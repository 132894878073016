<template>
  <div class="archause_home">
    <!-- <div class="extended wfill centered flex">
      <div
        class="connectaid_hero"
        src="../assets/arcause_final.jpg"
        style="margin: 0px auto 0 auto;"
      ></div>
    </div> -->
    <div
      class="connectaid_hero"
      v-if="connectaid.banner"
      :style="{
        background:
          $mq != 'mobile'
            ? 'url(' +
              (connectaid.banner || {}).url +
              ') top / contain no-repeat, url(../assets/connectaid_final.jpg) top / contain no-repeat'
            : 'url(' +
              (connectaid.mobileBanner || {}).url +
              ') top / contain no-repeat, url(../assets/connectaid_final.jpg) top / contain no-repeat'
      }"
    ></div>
    <div class="h20 white wfill"></div>
    <div class="wrap flex section2" style="z-index: -1;">
      <div
        v-if="$mq != 'mobile'"
        style="padding: 60px 0px 40px 40px;"
        class="section left flex vertical"
      >
        <h1 class="fs24">What is Connectaid?</h1>
        <p class="mt20 fs14" v-html="connectaid.Description"></p>
        <!-- <p class="mt20 fs14">
          Connectaid is an attempt to fill the void existing in the field of
          architecture and construction. Through this service from Ethos, firms
          looking out to recruit architects and engineers at different levels
          will be able to access talent. Young and mid-level architects and
          engineers looking out for job opportunities will be able to showcase
          their abilities and Ethos will make the same available for interested
          firms to view. Those interested may register themselves on Ethos
          Connect and fill up the online form. You would also be able to upload
          your portfolio (not larger than 1.5 MB) and provide links to your
          work. We request your support in making this service a success and
          look forward to your help in spreading the word.
        </p> -->
      </div>
      <!-- <div v-if="$mq != 'mobile'" class="hfill smear">
      </div>
      <div v-if="$mq != 'mobile'" class="cover"></div> -->
      <div
        class="hfill section right flex"
        style="position: relative; height: 400px;"
      >
        <h6
          class="myauto white together-text mxauto"
          style="text-align: left; line-height: 2em"
        >
          <span class="brown600">Hiring</span> the best<br />
        </h6>
        <div class="hands"></div>
      </div>
      <div
        v-if="$mq == 'mobile'"
        style="padding: 40px;"
        class="section left centered flex vertical"
      >
        <h6>What is ConnectAid?</h6>
        <p class="mt20 fs14" v-html="connectaid.Description"></p>
      </div>
    </div>
    <div :class="[$mq == 'mobile' ? '' : 'flex']">
      <div :style="{ width: $mq == 'mobile' ? '' : '50%' }" class="p40">
        <div
          class="relative forEmployersImage"
          style="left: -20px; top: 20px; width: 200px; height: 200px;"
        ></div>
        <h6 class="my20">For Employers</h6>
        <p>{{ connectaid.forEmployersText }}</p>
        <a :href="'mailto:' + connectaid.employerResponseEmail">
          <button class="mt20 black">Get in touch</button>
        </a>
      </div>
      <div :style="{ width: $mq == 'mobile' ? '' : '50%' }" class="p40">
        <div
          class="relative forEmployeesImage"
          style="left: -20px; top: 20px; width: 200px; height: 200px;"
        ></div>
        <h6 class="my20">For Employees</h6>
        <p>{{ connectaid.forEmployeesText }}</p>
        <button
          class="mt20 black"
          @click="
            $bus.redirect('https://forms.gle/ZLRMC8ddDZydsZBV6', false, true)
          "
        >
          Apply Now
        </button>
      </div>
    </div>
    <div style="border-top: 1px solid #eee;" class="pt40 px40 flex">
      <div>
        <h2>Job Listings</h2>
        <p class="nnn">
          {{ (connectaid.jobs || []).length }} open positions
          {{ $mq == "mobile" ? "" : "as of " + todaysDate }}
        </p>
      </div>
      <button
        class="brown500 mlauto"
        @click="
          $bus.redirect('https://forms.gle/ZLRMC8ddDZydsZBV6', false, true)
        "
      >
        Apply Now
      </button>
    </div>
    <div class="mt12 px40 flex wrap">
      <div class="vertical flex" :class="[$mq == 'mobile' && 'wfill mt4']">
        <label for="" style="color: transparent">Search</label>
        <div style="border: 1px solid #ccc; padding: 0 12px;">
          <i class="ml8 search icon"></i>
          <input
            placeholder="Search"
            type="text"
            class="search_input"
            style="border: none;"
            v-model="search"
          />
        </div>
      </div>
      <div
        class="vertical flex"
        :class="[$mq == 'mobile' ? 'wfill mt4' : 'ml12']"
      >
        <label for="">Type</label>
        <select v-model="filter.type" class="capitalize">
          <option value="" selected>By Type</option>
          <option class="capitalize" v-for="t in types" :key="t" :value="t">{{
            t
          }}</option>
        </select>
      </div>
      <div
        class="vertical flex"
        :class="[$mq == 'mobile' ? 'wfill mt4' : 'ml12']"
      >
        <label for="">Location</label>
        <select v-model="filter.location" class="capitalize">
          <option value="" selected>By Location</option>
          <option
            class="capitalize"
            v-for="l in locations"
            :key="l"
            :value="l"
            >{{ l }}</option
          >
        </select>
      </div>
    </div>
    <table
      v-if="filteredJobs.length > 0"
      class="px40 ui table"
      style="width: 100%; padding: 20px 40px; border: none;"
    >
      <thead>
        <tr>
          <th>Title & Location</th>
          <th>Type</th>
          <th>Experience</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="job in filteredJobs"
          :key="job.title"
          style="padding-bottom: 24px;"
        >
          <td>
            <p class="fw500 fs14">{{ job.title }}</p>
            <p style="color: #999" class="fs12">{{ job.location }}</p>
          </td>
          <td class="capitalize">{{ job.category }}</td>
          <td>
            {{ job.experienceRangeStart }}
            <span v-if="job.experienceRangeEnd">
              - {{ job.experienceRangeEnd }}</span
            >
            <span v-else>+</span>
            years
          </td>
          <td style="width: 50%; padding-bottom: 40px;">
            <p v-if="job.qualifications" class="fw500 fs14">Qualifications</p>
            {{ job.qualifications }}
            <br /><br />
            <span v-if="job.skills">
              <p class="fw500 fs14">Skills</p>
              {{ job.skills }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
// import campaign from '../components/campaign'
export default {
  name: "Connectaid",
  metaInfo() {
    return {
      title: this.connectaid?.SEO?.metaTitle || "Ethos | Connectaid",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: this.connectaid?.SEO?.metaDescription || "Job opportunities, hiring & placement for students & professionals of the Architecture, Construction, Engineering & Design (ACED) fraternity"
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.connectaid?.SEO?.metaKeywords || ""
        }
      ]
    }
  },
  components: {},
  data() {
    return {
      campaigns: [],
      connectaid: {},
      types: [],
      locations: [],
      search: "",
      filter: {
        type: "",
        location: ""
      }
    };
  },
  computed: {
    filteredJobs: function() {
      let jobs = [...this.connectaid.jobs];
      let f = this.filter;
      if (f.type) {
        jobs = jobs.filter(j => j.category == f.type);
      }
      if (f.location) {
        jobs = jobs.filter(j => j?.location?.toLowerCase() == f.location);
      }

      if (this.search) {
        jobs = jobs.filter(j => {
          let title = j.title || j.Title;
          let description = j.skills || j.Information;
          let qualifications = j.qualifications;
          return (
            title.toLowerCase().includes(this.search.toLowerCase()) ||
            description.toLowerCase().includes(this.search.toLowerCase()) ||
            qualifications.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      return jobs;
    },
    todaysDate: function() {
      return moment().format("Do MMM YYYY");
    }
  },
  mounted() {
    this.$req
      .get("connectaid")
      .then(r => {
        this.connectaid = r.data;
        this.types = this.connectaid.jobs
          .map(j => j.category)
          .filter((v, i, s) => s.indexOf(v) == i);
        this.locations = this.connectaid.jobs
          .map(j => j?.location?.toLowerCase())
          .filter((v, i, s) => s.indexOf(v) == i)
          .filter(v => v != "");
      })
      .catch(e => console.log(e));
  },
  methods: {
    parseDate: function(d) {
      return parseInt(moment(d).format("YYYYMMDD"));
    }
  }
};
</script>

<style lang="css" scoped>
.forEmployeesImage {
  background: url("../assets/for_employee.jpg") center / contain no-repeat;
}
.forEmployersImage {
  background: url("../assets/for_employer.jpg") center / contain no-repeat;
}
.archause_home {
  width: 100%;
  position: relative;
  padding: 0px;
  overflow: hidden;
}

table {
  padding: 20px 40px;
  border: none;
}
thead tr,
thead {
  background: #fff !important;
}

th {
  background: #fff !important;
  color: #9a9a9a !important;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}
td {
  vertical-align: top;
}

@media (max-width: 800px) {
  .archause_home {
    padding: 0px;
  }
}
.banner {
  padding: 8px;
  height: fit-content;
  white-space: nowrap;
  background: #252525;
  display: flex;
  justify-content: center;
}

.banner p {
  height: 24px;
  font-size: 16px;
  margin: auto 4px;
  font-weight: 600;
}
@media (max-width: 800px) {
  .banner p {
    font-size: 14px;
  }
  .banner {
    overflow-x: scroll;
  }
}
.banner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.banner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.connectaid_hero {
  height: 100vw;
  width: 100%;
  background: url("../assets/connectaid_final.jpg") top / cover no-repeat;
}
@media (min-width: 600px) {
  .connectaid_hero {
    height: 37vw;
    width: 100%;
    background: url("../assets/connectaid_final.jpg") top / contain no-repeat;
  }
}

/* .smear {
    background: url('../assets/archause_section_smear.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    position: relative;
    height: 500px;
    top: -190px;
    z-index: -1;
    left: 190px;
  } */

.section2 {
  height: 400px;
}
@media (max-width: 800px) {
  .section2 {
    height: fit-content;
  }
}
.section.left {
  width: calc(40%);
  position: relative;
  padding: 20px;
}
.section.right {
  background: url("../assets/smear.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 0px;
  width: 60%;
}

.hands {
  background: url("../assets/hands.svg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  bottom: 0px;
  height: 30%;
}

/* .cover {
    border-bottom-left-radius: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 300px 80px;
    border-color: transparent transparent #B98744 transparent;
  } */
@media (max-width: 800px) {
  .section.left {
    width: 100%;
    position: relative;
  }
  .section.right {
    background: #b98744;
    width: 100%;
    position: relative;
  }
  .mobile-center {
    text-align: center;
  }
}
@media (min-width: 800px) {
  .together-text {
    font-size: 20px;
    margin-left: auto;
    margin: auto;
  }
}
</style>
